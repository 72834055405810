<template>
  <div>
    <setting-quota
      v-if="checkAuthAbility('customer', 'API.Admin.Customer.Quota.Update')"
      @refresh-data="refreshCustomerInfoData"
    />
  </div>
</template>

<script>
import SettingQuota from './CustomerViewSettingQuota.vue'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  components: {
    SettingQuota,
  },
  mixins: [usePublicFunction],
  data() {
    return {
    }
  },
  methods: {
    refreshCustomerInfoData() {
      this.$emit('refresh-data')
    },
  },
  setup() {
  },
}
</script>

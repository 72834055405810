<template>
  <div>
    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              購物車列表
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchTable"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="創建日期"
                class="d-flex align-items-center actions-link-btn ml-25"
                :class="timeRange ? 'actions-link-btn-active' : null"
                @click="() => { $refs.TableTimeRange.getData(timeRange) }"
              >
                <b-img
                  src="/dashboard/admin/images/table/calendar.svg"
                  class="actions-link-btn-image"
                  rounded
                />

                <TableTimeRange
                  ref="TableTimeRange"
                  table-explorer-id="TableTimeRange"
                  @call-back-data="time => timeRange = time"
                />
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
            <!-- per-page-selector d-inline-block   -->
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getCartListData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        class="position-relative"
        details-td-class="p-0"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            hide-header
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 類別 -->
        <template #cell(type)="data">
          <div class="table-col">

            <div
              class="ribbon-col"
              @click="onSubmitLink(data.item)"
            >
              <span
                :style="{ backgroundColor: resolveProductType(data.item.product_type).color}"
                class="text-nowrap"
              >
                {{ resolveProductType(data.item.product_type).label }}
              </span>
            </div>
          </div>
        </template>

        <!-- 欄位: 商品 -->
        <template #cell(product)="data">
          <div
            class="cursor-pointer d-flex align-items-center justify-content-between"
          >
            <b-link
              class="font-weight-bold mb-0"
              @click="data.toggleDetails"
            >
              <div class="mb-0">
                <div class="item-description server-name">
                  {{ ui.type[data.item.type.toLowerCase()] }}
                </div>
                <small class="text-muted">
                  <div v-if="data.item.product_type === 'domain'">
                    {{ data.item.info.prefix }}{{ data.item.product_info.suffix }} / {{ data.item.info.quantity }}年
                  </div>

                  <div v-if="data.item.product_type === 'vps'" />

                  <div v-if="data.item.product_type === 'host'" />
                </small>
              </div>
            </b-link>
            <feather-icon
              :icon="data.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              class="ml-25"
              @click="data.toggleDetails"
            />
          </div>
        </template>

        <!-- 欄位: 上次更新 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 新增時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 詳細 -->
        <template #row-details="data">
          <div class="table-toggle-detail border-top p-1">
            <b-card
              no-body
              class="m-0 border animate__animated animate__fadeIn"
            >
              <div>
                <b-card-body>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label-cols="4"
                        label="商品SSID"
                        class="m-0 selection-group"
                      >
                        <div class="mt-50">
                          {{ data.item.product ? data.item.product : '---' }}
                          <span
                            v-b-tooltip.hover.focus.v-secondary
                            title="篩選"
                            class="selection-btn-icon"
                            @click="searchCol({
                              name: '商品SSID',
                              key: 'search_query',
                              value: data.item.product,
                            })"
                          >
                            <b-img
                              src="/dashboard/admin/images/table/filter2.svg"
                              height="15"
                              width="15"
                              class="cursor-pointer"
                            />
                          </span>
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col
                      v-if="data.item.product_type === 'domain'"
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label-cols="4"
                        label="網域後綴"
                        class="m-0 selection-group"
                      >
                        <div class="mt-50">
                          {{ data.item.product_info.suffix }}
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col
                      v-for="(value, key) in data.item.info"
                      :key="key"
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label-cols="4"
                        :label="productDictionary[data.item.product_type][key] || key"
                        class="m-0 selection-group"
                      >
                        <div class="mt-50">
                          {{ value }}
                        </div>
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-card-body>
              </div>
            </b-card>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <div class="d-flex mb-50">
            <div
              class="actions-link-btn mr-25"
              @click="onSubmitLink(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="商品詳情"
                src="/dashboard/admin/images/table/external-link.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-if="checkAuthAbility('cart', 'API.Admin.Cart.Delete')"
              class="actions-link-btn mr-25"
              @click="onSubmitDelete(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="刪除"
                src="/dashboard/admin/images/table/delete.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BCardBody, BInputGroupAppend,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'
import { useCartCustomerList, useCartSetting } from '@/views/admin/cart/useCart'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '@/views/admin/cart/useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BFormInput,
    BCardBody,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    TableTimeRange,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (搜尋)點擊的欄位
    searchCol(item) {
      if (item.key === 'search_query') {
        this.searchQuery = item.value
      }
    },

    // (前往)商品
    onSubmitLink(item) {
      if (item.product_type === 'domain') {
        this.$router.push({
          name: 'admin-domain-price-setting',
          params: {
            id: item.product_info.id,
          },
        })
      }
    },

    // (刪除)購物車
    onSubmitDelete(item) {
      let productInfo = '購物車產品'
      if (item.product_type === 'domain') {
        productInfo = `${this.ui.type[item.type.toLowerCase()]} ${item.info.prefix}${item.product_info.suffix} / ${item.info.quantity}年`
      }
      this.useSwalAlertWarning('刪除購物車商品', `你確定要永久刪除${productInfo} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setCartDelete({
              cart_id: [item.id],
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', '購物車商品已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    const CART_ADMIN_STORE_MODULE_NAME = 'admin-cart'

    if (!store.hasModule(CART_ADMIN_STORE_MODULE_NAME)) store.registerModule(CART_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(CART_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(CART_ADMIN_STORE_MODULE_NAME)
    })

    const customerID = router.currentRoute.params.id

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      setCartDelete,
      getCartListData,
    } = useCartCustomerList(customerID)

    const {
      ui,
      resolveProductType,
      productDictionary,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    } = useCartSetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    return {
      ui,
      avatarText,
      productDictionary,
      resolveProductType,
      useAlertToast,
      dateConfig2,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      getCartListData,
      setCartDelete,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.ribbon-col {
  span {
    padding: 3px 8px;
    border-radius: 10px;
    border-style: outset;
    background-color: #3498dbe7;
    color: #fff;
    text-shadow: 0 1px 1px rgb(0, 0, 0);
    text-transform: uppercase;
    text-align: center;
    font-size: 12px
  }
}
.selection-group {
  .selection-btn {
    display: none;
    margin-left: 10px;
    padding: 3px 5px;
  }
  .selection-btn-icon {
    display: none;
    margin-left: 3px;
  }
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
    .selection-btn {
      display: inline-block;
    }
    .selection-btn-icon {
      display: inline-block;
    }
    .selection-btn-icon-show {
      opacity: 1;
    }
  }
}
.item-description-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}
</style>

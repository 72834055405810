<template>
  <div>
    <b-overlay
      :show="adminCustomerState.isBusyLoading"
      variant="transparent"
      opacity="0.85"
      rounded="sm"
      blur="5px"
    >
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            錢包
          </h4>
        </b-card-header>

        <b-card-body class="ml-50">
          <b-row>
            <b-col
              cols="12"
              lg="4"
            >
              <div
                class="link-card text-center"
              >
                <b-img
                  :src="require('@/assets/images/pages/order/point-wallet.svg')"
                  class="link-card-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  錢包餘額
                </h5>

                <small v-if="!adminCustomerState.isBusyLoading">
                  $ {{ parseInt(adminCustomerState.customerInfo.point, 10).toLocaleString() }} NTD
                </small>
              </div>
            </b-col>

            <b-col
              cols="12"
              lg="8"
            >
              <h6 class="mb-50">
                點數增加
              </h6>

              <div>
                <b-button
                  v-for="btn in moneyBtn"
                  :key="btn"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  size="sm"
                  variant="outline-success"
                  class="mr-50 mb-75"
                  @click="targetPoint('add', btn)"
                >
                  ＋${{ btn.toLocaleString() }}
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  size="sm"
                  variant="outline-success"
                  class="mr-50 mb-75"
                  @click="targetPoint('add', null)"
                >
                  自訂
                </b-button>
              </div>

              <hr>

              <h6 class="mb-50">
                點數減少
              </h6>

              <div>
                <b-button
                  v-for="btn in moneyBtn"
                  :key="btn"
                  variant="outline-danger"
                  size="sm"
                  class="mr-50 mb-75"
                  @click="targetPoint('sub', btn)"
                >
                  －${{ btn.toLocaleString() }}
                </b-button>

                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="mr-50 mb-75"
                  @click="targetPoint('sub', null)"
                >
                  自訂
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>

    <b-modal
      id="point-modal"
      centered
      @hidden="handleHide"
      @close="handleHide"
    >
      <template #modal-title>
        <h4 class="m-0">
          自訂點數
        </h4>
      </template>

      <div class="modal-content-area">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="mb-1"
          >
            <v-select
              v-model="pointData.type"
              :options="[{
                label: '增加',
                value: 'add'
              }, {
                label: '減少',
                value: 'sub'
              }]"
              class="b-form-select w-100"
              :reduce="option => option.value"
              :clearable="false"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            sm="8"
            class="mb-1"
          >
            <div>
              <b-form-input
                v-model="pointData.point"
                debounce="500"
                class="d-inline-block"
                placeholder="1,000"
                type="number"
              />
            </div>
          </b-col>

        </b-row>
        <div>
          <b-form-textarea
            v-model="pointData.content"
            rows="3"
            no-resize
            placeholder="請填寫點數操作說明"
          />
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            :disabled="isTableBusy"
            @click="handleOk"
          >
            <span v-if="!isTableBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BCardBody, BRow, BCol, BCardHeader, VBTooltip, BImg, BButton, BModal, BSpinner, BFormTextarea,
  BFormInput, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useCustomerViewGeneral } from '../../useCustomer'
import { useSwalToast, useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BModal,
    BSpinner,
    BButton,
    BCardBody,
    BCardHeader,
    BFormTextarea,
    BFormInput,
    vSelect,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  computed: {
    adminCustomerState() {
      return this.$store.state['admin-customer']
    },
  },
  methods: {
    moment,
    // (操作)點數
    targetPoint(type, point) {
      const typeText = {
        add: '充值點數',
        sub: '使用點數',
      }

      const alertText = `你確定要幫會員${typeText[type]} ${point ? parseInt(point, 10).toLocaleString() : ''} 點嗎?`
      const contentText = `系統${typeText[type]}${point ? parseInt(point, 10).toLocaleString() : ''}點`
      const pointValue = type === 'add' ? Math.abs(point) : Math.abs(point) * -1

      if (!point) {
        this.pointData.type = type
        this.$bvModal.show('point-modal')
        return
      }

      this.useSwalAlertWarning(`${typeText[type]}`, alertText)
        .then(result => {
          if (result.value) {
            this.setCustomerPointUpdate({
              customer_id: this.adminCustomerState.customerInfo.id,
              data: {
                point: pointValue,
                content: contentText,
              },
            }).then(response => {
              const { data } = response.data
              const resolvPoint = data.before_quota + data.point
              const resolveCustomerInfo = {
                ...this.adminCustomerState.customerInfo,
              }
              resolveCustomerInfo.point = resolvPoint
              this.$store.commit('admin-customer/UPDATE_CUSTOMER_INFO_STATE', resolveCustomerInfo)
              this.useAlertToast(response.data.success, response.data.message)
            })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
              })
          }
        })
    },

    // (關閉)確認編輯
    handleHide() {
      this.$bvModal.hide('point-modal')
      this.resetPointData()
    },

    handleOk() {
      if (!this.pointData.point) {
        this.useAlertToast(false, '點數不可為空')
        return
      }
      const typeText = {
        add: '充值點數',
        sub: '使用點數',
      }

      const { type, point, content } = this.pointData

      const alertText = `你確定要幫會員${typeText[type]} ${point ? parseInt(Math.abs(point), 10).toLocaleString() : ''} 點嗎?`
      const pointValue = type === 'add' ? Math.abs(point) : Math.abs(point) * -1
      this.$bvModal.hide('point-modal')

      this.useSwalAlertWarning(`${typeText[type]}`, alertText)
        .then(result => {
          if (result.value) {
            this.setCustomerPointUpdate({
              customer_id: this.adminCustomerState.customerInfo.id,
              data: {
                point: pointValue,
                content,
              },
            }).then(response => {
              const { data } = response.data
              const resolvPoint = data.before_quota + data.point
              const resolveCustomerInfo = {
                ...this.adminCustomerState.customerInfo,
              }
              resolveCustomerInfo.point = resolvPoint
              this.$store.commit('admin-customer/UPDATE_CUSTOMER_INFO_STATE', resolveCustomerInfo)
              this.resetPointData()
              this.useAlertToast(response.data.success, response.data.message)
            })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
              })
          }
        })
    },
  },
  setup() {
    const moneyBtn = [10, 30, 50, 100, 200, 300, 500, 1000, 3000, 5000, 10000]

    const {
      syncObject,
    } = useCustomerViewGeneral()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      setCustomerPointUpdate,
    } = useCustomerViewGeneral()

    const pointData = ref(null)
    const isTableBusy = ref(false)

    const blankData = {
      type: null,
      point: 0,
      content: '',
    }

    pointData.value = JSON.parse(JSON.stringify(blankData))

    const resetPointData = () => {
      pointData.value = JSON.parse(JSON.stringify(blankData))
    }

    return {
      moneyBtn,
      pointData,
      isTableBusy,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,
      setCustomerPointUpdate,
      resetPointData,
    }
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>

.link-card {
  padding: 50px;
  border: 1px solid #d5d2dc1a;
  background-color: #b8b7bb1d;
  margin-bottom: 10px;
  border-radius: 10px;
  .link-card-image {
    max-width: 70px;
    max-height: 70px;
    margin-bottom: 10px;
  }

  .text-body-heading {
    margin-top: 5px;
    font-size: 20px;
    font-weight: bolder;
  }

  &:hover {
    cursor: pointer;
    .link-card-image {
      animation: breathe .8s linear infinite;
    }
  }
}

</style>

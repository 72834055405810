<template>
  <div>
    <b-tabs
      pills
      lazy
    >
      <!-- tab: 點數紀錄 -->
      <b-tab v-if="checkAuthAbility('customer', 'API.Admin.Customer.Quota')">
        <template #title>
          <feather-icon
            icon="StopCircleIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">點數紀錄</span>
        </template>

        <history-quota-list />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import HistoryQuotaList from './CustomerViewHistoryQuotaList.vue'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  components: {
    BTabs,
    BTab,
    HistoryQuotaList,
  },
  mixins: [usePublicFunction],
  data() {
    return {
    }
  },
  methods: {
  },
  setup() {
  },
}
</script>

<template>
  <div>
    <!-- 頁面: 會員詳情(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('customer', 'API.Admin.Customer.Id')">
      <b-tabs
        v-model="tabIndex"
        vertical
        content-class="col-12 col-lg-10 mt-1 mt-md-0"
        pills
        lazy
        nav-wrapper-class="col-lg-2 col-12"
        nav-class="nav-left"
      >
        <template slot="default">
          <customer-view-info
            @refresh-data="refreshCustomerInfoData"
          />
        </template>

        <!-- tab: 會員詳情 -->
        <b-tab>
          <template #title>
            <b-img
              rounded
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/customer/ui-user.svg')"
            />
            <span class="font-weight-bold">會員詳情</span>
          </template>

          <customer-view-general
            @refresh-data="refreshCustomerInfoData"
          />
        </b-tab>

        <!-- tab: 訂單列表 -->
        <b-tab v-if="checkAuthAbility('customer', 'API.Admin.Customer.Cart')">
          <template #title>
            <b-img
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/customer/ui-order.svg')"
            />
            <span class="font-weight-bold">訂單列表</span>
          </template>

          <customer-view-order />
        </b-tab>

        <!-- tab: 工作階段 -->
        <b-tab>
          <template #title>
            <b-img
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/customer/ui-login.svg')"
            />
            <span class="font-weight-bold">工作階段</span>
          </template>

          <customer-view-workphase />
        </b-tab>

        <!-- tab: 操作紀錄 -->
        <b-tab v-if="checkAuthAbility('customer', 'API.Admin.Customer.Quota')">
          <template #title>
            <b-img
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/customer/ui-history.svg')"
            />
            <span class="font-weight-bold">操作紀錄</span>
          </template>

          <customer-view-history />
        </b-tab>

        <!-- tab: 設定 -->
        <b-tab v-if="checkAuthAbility('customer', 'API.Admin.Customer.Quota.Update')">
          <template #title>
            <b-img
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/customer/ui-settings.svg')"
            />
            <span class="font-weight-bold">設定</span>
          </template>

          <customer-view-setting
            @refresh-data="refreshCustomerInfoData"
          />
        </b-tab>

      </b-tabs>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import {
  BTabs, BTab, BImg, BAlert, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import CustomerViewInfo from './CustomerViewInfo.vue'
import CustomerViewOrder from './CustomerViewOrder/CustomerViewOrder.vue'
import CustomerViewHistory from './CustomerViewHistory/CustomerViewHistory.vue'
import CustomerViewGeneral from './CustomerViewGeneral/CustomerViewGeneral.vue'
import CustomerViewWorkphase from './CustomerViewWorkphase.vue'
import CustomerViewSetting from './CustomerViewSetting/CustomerViewSetting.vue'
import { useCustomerViewGeneral } from '../useCustomer'
import useStoreModule from '../useStoreModule'
import { usePublicFunction } from '@/libs/mixins/index'
import router from '@/router'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BTabs,
    BTab,
    BImg,
    BAlert,
    BLink,

    CustomerViewInfo,
    CustomerViewOrder,
    CustomerViewHistory,
    CustomerViewGeneral,
    CustomerViewWorkphase,
    CustomerViewSetting,
  },
  mixins: [usePublicFunction],
  data() {
    return {
      tabIndex: 0,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  setup() {
    const CUSTOMER_ADMIN_STORE_MODULE_NAME = 'admin-customer'

    if (!store.hasModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)
    })

    const customerID = router.currentRoute.params.id

    const {
      generalData,
      getCustomerInfoData,
    } = useCustomerViewGeneral()

    const refreshCustomerInfoData = () => {
      store.commit('admin-customer/UPDATE_BUSY_LOADING_STATE', true)
      getCustomerInfoData({ customer_id: customerID }, () => {
        store.commit('admin-customer/UPDATE_BUSY_LOADING_STATE', false)
      })
    }

    refreshCustomerInfoData()

    return {
      customerID,
      generalData,
      getCustomerInfoData,
      refreshCustomerInfoData,
    }
  },
}
</script>

<template>
  <div>
    <div>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            變更密碼
          </h4>
        </b-card-header>

        <b-card-body>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                  class="mb-1"
                >
                  <!-- 新密碼 -->
                  <validation-provider
                    #default="{ errors }"
                    name="新密碼"
                    rules="required"
                  >

                    <b-form-group label-for="userPassword-newPassword">
                      <template #label>
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="mb-0">
                            新密碼
                            <span class="text-danger">*</span>
                          </label>
                        </div>
                      </template>

                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="userPassword-newPassword"
                          v-model="userPassword.newPassword"
                          :type="passwordFieldType[0]"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="userPassword-newPassword"
                          placeholder="請輸入新密碼"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon(0)"
                            class="cursor-pointer"
                            @click="togglePassword(0)"
                          />
                        </b-input-group-append>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>

                    <!-- 密碼驗證 -->
                    <div class="power-tips">
                      <b-row class="tips">
                        <b-col
                          class="tips-item"
                          :class="(userRegPwdLevel >= 1 && userPassword.newPassword) ? `${passwordStyle(userRegPwdLevel)}` : ''"
                        />

                        <b-col
                          class="tips-item"
                          :class="(userRegPwdLevel >= 2) ? `${passwordStyle(userRegPwdLevel)}` : ''"
                        />

                        <b-col
                          class="tips-item"
                          :class="(userRegPwdLevel >= 3) ? `${passwordStyle(userRegPwdLevel)}` : ''"
                        />

                        <b-col
                          class="tips-item"
                          :class="(userRegPwdLevel >= 4) ? `${passwordStyle(userRegPwdLevel)}` : ''"
                        />
                      </b-row>

                      <b-row class="tips">
                        <b-col>
                          <div
                            v-if="userRegPwdLevel === 1"
                            class="text-center"
                          >
                            <span>弱</span>
                          </div>
                        </b-col>

                        <b-col>
                          <div
                            v-if="userRegPwdLevel === 2"
                            class="text-center"
                          >
                            <span>中</span>
                          </div>
                        </b-col>

                        <b-col>
                          <div
                            v-if="userRegPwdLevel === 3"
                            class="text-center"
                          >
                            <span>強</span>
                          </div>
                        </b-col>

                        <b-col>
                          <div
                            v-if="userRegPwdLevel === 4"
                            class="text-center"
                          >
                            <span>很強</span>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </validation-provider>

                  <!-- 重複新密碼 -->
                  <validation-provider
                    #default="{ errors }"
                    name="重複新密碼"
                    rules="required"
                  >

                    <b-form-group label-for="userPassword-repeatPassword">
                      <template #label>
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="mb-0">
                            重複新密碼
                            <span class="text-danger">*</span>
                          </label>
                        </div>
                      </template>

                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="userPassword-repeatPassword"
                          v-model="userPassword.repeatPassword"
                          :type="passwordFieldType[1]"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="userPassword-repeatPassword"
                          placeholder="請重複輸入新密碼"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon(1)"
                            class="cursor-pointer"
                            @click="togglePassword(1)"
                          />
                        </b-input-group-append>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                  class="my-1"
                >
                  <!-- 密碼產生器 -->
                  <div class="border p-1 password-card">
                    <b-row class="mb-1">
                      <b-col
                        cols="12"
                        md="3"
                        sm="4"
                      >
                        <label>長度</label>
                      </b-col>

                      <b-col
                        cols="12"
                        md="9"
                        sm="8"
                      >
                        <b-form-input
                          v-model="size"
                          type="number"
                          class="form-control w-50"
                          placeholder="密碼長度"
                          size="sm"
                          @change="changeSize"
                        />
                      </b-col>
                    </b-row>

                    <b-row class="mb-1">
                      <b-col
                        cols="12"
                        md="3"
                        sm="4"
                      >
                        <label>字母</label>
                      </b-col>

                      <b-col
                        cols="12"
                        md="9"
                        sm="8"
                      >
                        <b-form-radio-group
                          v-model="lettersType"
                          :options="lettersOptions"
                          name="letters-type"
                        />
                      </b-col>
                    </b-row>

                    <b-row class="mb-1">
                      <b-col
                        cols="12"
                        md="3"
                        sm="4"
                      >
                        <label>數字和符號</label>
                      </b-col>

                      <b-col
                        cols="12"
                        md="9"
                        sm="8"
                      >
                        <b-form-radio-group
                          v-model="numbersignType"
                          :options="numbersignOptions"
                          name="umbersign-type"
                        />
                      </b-col>
                    </b-row>

                    <div class="text-right">
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="generate"
                      >
                        生成
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <div
            v-if="!adminCustomerState.isBusyLoading"
            class="w-100 text-right"
          >
            <b-button
              variant="primary"
              :disabled="isBusy"
              @click="handleOk"
            >
              <span v-if="!isBusy">變更</span>

              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  BRow, BCol, BForm, VBToggle, BFormGroup, BFormInput, BInputGroupAppend, BCard, BCardHeader,
  BCardBody, BInputGroup, VBTooltip, BFormRadioGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, password } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useCustomerSetting, useCustomerViewGeneral } from '../../useCustomer'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BInputGroupAppend,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      required,
      password,
      missingFields: [],
      passwordFieldType: ['text', 'password'],
      size: 24,
      characters: 'a-z,A-Z,0-9,#',
      lettersType: 'a-z,A-Z',
      lettersOptions: [
        { text: '兩者(aBcD)', value: 'a-z,A-Z' },
        { text: '小寫字母(abcd)', value: 'a-z' },
        { text: '大寫字母(ABCD)', value: 'A-Z' },
      ],
      numbersignType: '0-9,#',
      numbersignOptions: [
        { text: '兩者(1@3$)', value: '0-9,#' },
        { text: '數字(1234)', value: '0-9' },
        { text: '符號(@#$)', value: '#' },
      ],
    }
  },
  computed: {
    adminCustomerState() {
      return this.$store.state['admin-customer']
    },
  },
  watch: {
    'adminCustomerState.customerInfo': {
      handler() {
        this.resetUserPassword()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.adminCustomerState.customerInfo) {
      this.resetUserPassword()
    }
  },
  methods: {
    // 顯示密碼/隱藏密碼
    togglePassword(index) {
      const newValue = this.passwordFieldType[index] === 'password' ? 'text' : 'password'
      this.passwordFieldType.splice(index, 1, newValue)
    },
    // 顯示密碼/隱藏密碼 - ICON
    passwordToggleIcon(index) {
      return this.passwordFieldType[index] === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },

    // 亂數產密碼
    generate() {
      this.characters = `${this.lettersType},${this.numbersignType}`
      const charactersArray = this.characters.split(',')
      let CharacterSet = ''
      let randpassword = ''

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
      }
      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|'
      }

      for (let i = 0; i < this.size; i += 1) {
        randpassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
      }
      this.userPassword.newPassword = randpassword
    },

    // 長度最小限制
    changeSize() {
      if (this.size < 8) this.size = 8
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        newPassword: '新密碼',
        repeatPassword: '確認新密碼',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.userPassword[Object.keys(requiredFields)[i]] === null || this.userPassword[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }
      if (this.userPassword.newPassword !== this.userPassword.repeatPassword) {
        this.useAlertToast(false, '新密碼與確認新密碼不一致')
        return
      }

      if (this.userPassword.newPassword.length < 8) {
        this.useAlertToast(false, '密碼最少需要8個字元長')
        return
      }

      this.isBusy = true

      const resolveData = {
        newPassword: this.userPassword.newPassword,
      }

      this.setCustomerPasswordUpdate({
        customer_id: this.adminCustomerState.customerInfo.id,
        data: resolveData,
      }).then(response => {
        this.useAlertToast(response.data.success, response.data.message)
        this.resetUserPassword()
      })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
    } = useCustomerSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankPasswordData = {
      newPassword: '',
      repeatPassword: '',
    }

    const isBusy = ref(false)
    const userPassword = ref(JSON.parse(JSON.stringify(blankPasswordData)))
    const userRegPwdLevel = ref(0)

    const resetUserPassword = () => {
      userPassword.value = JSON.parse(JSON.stringify(blankPasswordData))
      userRegPwdLevel.value = 0
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserPassword)

    const lengthWeakness = psw => {
      const { length } = psw

      if (length <= 5) {
        return {
          message: '你的密碼太短',
          deduction: 40,
        }
      }

      if (length <= 10) {
        return {
          message: '你的密碼可以再長一點',
          deduction: 15,
        }
      }

      if (length <= 15) {
        return {
          message: '',
          deduction: -20,
        }
      }
      return null
    }

    const characterTypeWeakness = (psw, regex, type) => {
      const matches = psw.match(regex) || []

      if (matches.length === 0) {
        return {
          message: `你的密碼沒有 ${type}`,
          deduction: 20,
        }
      }

      if (matches.length <= 2) {
        return {
          message: `你的密碼可以用更多 ${type}`,
          deduction: 5,
        }
      }
      return null
    }

    const repeatCharactersWeakness = psw => {
      const matches = psw.match(/(.)\1/g) || []
      if (matches.length > 0) {
        return {
          message: 'Your password has repeat characters',
          deduction: matches.length * 10,
        }
      }
      return null
    }

    const calculatePasswordStrength = psw => {
      const weaknesses = []
      weaknesses.push(lengthWeakness(psw))
      weaknesses.push(characterTypeWeakness(psw, /[A-Z]/g, '大寫英文'))
      weaknesses.push(characterTypeWeakness(psw, /[a-z]/g, '小寫英文'))
      weaknesses.push(characterTypeWeakness(psw, /[0-9]/g, '數字'))
      weaknesses.push(characterTypeWeakness(psw, /[^0-9a-zA-Z\s]/g, '特殊字元'))
      weaknesses.push(repeatCharactersWeakness(psw))
      return weaknesses
    }

    const passwordCheck = value => {
      const weaknesses = calculatePasswordStrength(value)
      let strength = 100
      weaknesses.forEach(weakness => {
        if (weakness == null) return
        strength -= weakness.deduction
        const messageElement = document.createElement('div')
        messageElement.innerText = weakness.message
      })
      switch (true) {
        case strength > 90: {
          userRegPwdLevel.value = 4
          break
        }
        case strength > 49: {
          userRegPwdLevel.value = 3
          break
        }
        case strength > 20: {
          userRegPwdLevel.value = 2
          break
        }
        default:
          userRegPwdLevel.value = 1
          break
      }
    }

    watch(() => userPassword.value.newPassword, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        passwordCheck(newValue)
      }
    })

    const passwordStyle = state => {
      if (state === 1) return 's1'
      if (state === 2) return 's2'
      if (state === 3) return 's3'
      if (state === 4) return 's4'
      return ''
    }

    const {
      setCustomerPasswordUpdate,
    } = useCustomerViewGeneral()

    return {
      isBusy,

      userPassword,
      userRegPwdLevel,
      passwordStyle,
      passwordCheck,
      blankPasswordData,

      resetUserPassword,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
      setCustomerPasswordUpdate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<!-- 密碼強度-SCSS -->
<style lang="scss" scope>
.password-card {
  border-radius: 10px;
}
.power-tips {
  height: 40px;
}
.tips {
  margin: 5px 0 5px 0;
  .tips-item {
    margin-right: 5px;
    height: 8px;
    border-radius: 5px;
    background: #d7d9dd;
  }
  .s1 {
    background: #ff0000;
  }
  .s2 {
    background: #ff8f00;
  }
  .s3 {
    background: #ffcc00;
  }
  .s4 {
    background: #00ca22;
  }
}
</style>

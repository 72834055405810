<template>
  <div>
    <b-tabs
      pills
      lazy
    >
      <!-- tab: 基本資料 -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">基本資料</span>
        </template>

        <general-base
          @refresh-data="refreshCustomerInfoData"
        />
      </b-tab>

      <!-- tab: 變更密碼 -->
      <b-tab v-if="checkAuthAbility('customer', 'API.Admin.Customer.Password')">
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">變更密碼</span>
        </template>

        <general-password />
      </b-tab>

      <!-- tab: 權限驗證 -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="KeyIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">權限驗證</span>
        </template>
        <general-kyc />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import GeneralBase from './CustomerViewGeneralBase.vue'
import GeneralKyc from './CustomerViewGeneralKyc.vue'
import GeneralPassword from './CustomerViewGeneralPassword.vue'

import router from '@/router'
import store from '@/store'
import { useCustomerViewGeneral } from '../../useCustomer'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  components: {
    BTabs,
    BTab,

    GeneralBase,
    GeneralKyc,
    GeneralPassword,
  },
  mixins: [usePublicFunction],
  data() {
    return {
    }
  },
  methods: {
  },
  setup() {
    const {
      getCustomerInfoData,
    } = useCustomerViewGeneral()

    const customerID = router.currentRoute.params.id

    const refreshCustomerInfoData = () => {
      store.commit('admin-customer/UPDATE_BUSY_LOADING_STATE', true)
      getCustomerInfoData({ customer_id: customerID }, () => {
        store.commit('admin-customer/UPDATE_BUSY_LOADING_STATE', false)
      })
    }

    return {
      refreshCustomerInfoData,
    }
  },
}
</script>

<template>
  <div>
    <b-tabs
      pills
      lazy
    >
      <!-- tab: 訂單列表 -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="ListIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">訂單列表</span>
        </template>

        <!-- <general-base
          @refresh-data="refreshCustomerInfoData"
        /> -->
      </b-tab>

      <!-- tab: 購物車列表 -->
      <b-tab
        v-if="checkAuthAbility('customer', 'API.Admin.Customer.Cart')"
        active
      >
        <template #title>
          <feather-icon
            icon="ShoppingCartIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">購物車列表</span>
        </template>

        <order-cart-list />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import OrderCartList from './CustomerViewOrderCartList.vue'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  components: {
    BTabs,
    BTab,
    OrderCartList,
  },
  mixins: [usePublicFunction],
  data() {
    return {
    }
  },
  methods: {
  },
  setup() {
  },
}
</script>
